import { ApiService } from './api.service';
import { Injectable }     from '@angular/core';
import { CanActivate}    from '@angular/router';

@Injectable()
export class APIGuard implements CanActivate {
    status: boolean = false;
    constructor(private _apiService: ApiService) {}
    canActivate(){
        return this._apiService.getPages().then(()=>{
            return true;
        });
    }
}