import { Injectable }     from '@angular/core';
import { CanActivate,Router }    from '@angular/router';
import { ThankYouService } from './thank-you.service';

@Injectable()
export class ThankYouContactGuard implements CanActivate {
    constructor(private router: Router, private tyService: ThankYouService) {}
    canActivate() {
        if (this.tyService.allowContactTY) {
            return true;
        } else {
            this.router.navigate(['/']);
            return false;
        }
        
    }
}